
export default {
  name: 'MarketLinks',
  props: {
    showOnly: {
      type: String,
      default: 'all',
    },
    width: {
      type: String,
      default: '121',
    },
    height: {
      type: String,
      default: '27',
    },
  },
}
